import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./../views/Home.vue')
  }, {
    path: '/parcels/view/buy',
    name: 'Settings.ParcelsBuy',
    component: () => import('./../views/Parcels.vue')
  }, {
    path: '/parcels/view/forward',
    name: 'Settings.ParcelsForward',
    component: () => import('./../views/Parcels.vue')
  }, {
    path: '/parcel/:parcelId',
    name: 'Settings.ParcelView',
    component: () => import('./../views/Parcel.vue')
  }, {
    path: '/settings/contries',
    name: 'Settings.Countries',
    component: () => import('./../views/Countries.vue')
  }, {
    path: '/delivery/warehouses',
    name: 'Settings.Warehouses',
    component: () => import('./../views/Warehouses.vue')
  }, { 
    path: '/delivery/addresses',
    name: 'Settings.Addresses',
    component: () => import('./../views/Addresses.vue')
  }, {
    path: '/delivery/warehouses/:warehouseId',
    name: 'Settings.Warehouse',
    component: () => import('./../views/Warehouse.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
