<template lang='pug'>
  .dialog-container
    transition(name='fade')
      .backdrop(v-if='dialog && dialog.settings.backdrop' :style='`background: ${dialog.settings.backdrop}`' @click='localCloseDialog()')
    transition(name='scaling')
      .dialog(v-if='dialog' :class='dialog.settings.position')
        .uniqcontent
          v-component(v-bind='dialog.component')
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CModal',
  mounted() {
    this.$root.openDialog = this.openDialog;
    this.$root.closeDialog = this.closeDialog;
  },
  computed: {
    ...mapGetters({
      dialogQueue: 'dialogQueue'
    }),
    dialog() {
      if (this.reopenDelay) {
        return null;
      }

      return this.dialogQueue[0];
    },
  },
  data() {
    return {
      reopenDelay: false
    }
  },
  methods: {
    openDialog(obj, force = false) {
      this.$store.commit('dialogQueue', {
        dialog: Object.assign({
          settings: {
            backdrop: '#00000060',
            position: 'center'
          }
        }, obj),
        force: force
      });
    },
    closeDialog(clear = false) {
      this.$store.commit('dialogClose', {
        clear: clear
      });
    },
    localCloseDialog() {
      this.reopenDelay = true;

      this.$root.closeDialog();

      setTimeout(() => {
        this.reopenDelay = false; 
      }, 250);
    }
  }
}
</script>

<style lang='scss'>
.dialog-container {
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .backdrop {
    position: fixed;
    pointer-events: all;
    width: 100%;
    height: 100%;
    z-index: 998;
    cursor: pointer;
  }

  .dialog {
    position: fixed;
    z-index: 999;
    pointer-events: none;

    &.center {
      width: 100%;
      height: 100%;
    }

    &.bottom, &.top {
      width: 100%;
    }

    .uniqcontent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: all;
    }
  }
}


.scaling-leave-active {
  animation: scale-out .3s cubic-bezier(0.71, 0.16, 0, 1.04) both;
}
.scaling-enter-active {
  animation: scale-in .3s cubic-bezier(0.36, 0.01, 0, 1.32) both;
}

@keyframes scale-in {
  0% {
      transform: scale(0);
      -webkit-transform: scale(0);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      -webkit-transform: scale(1);
      opacity: 1;
  }
}  

@keyframes scale-out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}

.fade-leave-active {
  animation: fade-out .3s cubic-bezier(0.71, 0.16, 0, 1.04) both;
}
.fade-enter-active {
  animation: fade-in .3s cubic-bezier(0.36, 0.01, 0, 1.32) both;
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}  
@keyframes fade-out {
  0% {
    pointer-events: none;
      opacity: 1;
  }
  100% {
    pointer-events: none;
      opacity: 0;
  }
}
</style>