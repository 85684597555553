<template lang='pug'>
  div
    template(v-for='(section, index) in sections')
      .text-sm.opacity-70 {{ section.name }}
      .routes.mb-2.flex.flex-col
        template(v-for='(route, indexRoute) in section.routes')
          button(@click='choose(route)' :class='{ "font-bold": currentPath.includes(route.path) }') {{ route.name }}
</template>

<script>
export default {
  computed: {
    currentPath() {
      return this.$route.path;
    }
  },
  data() {
    return {
      sections: [{
        name: 'Заказы',
        routes: [{
          name: 'Запросы на пересылку',
          path: '/parcels/view/forward'
        }, {
          name: 'Запросы на покупку',
          path: '/parcels/view/buy'
        }]
      }, {
        name: 'Логистика',
        routes: [{
          name: 'Склады',
          path: '/delivery/warehouses'
        }, {
          name: 'Адреса',
          path: '/delivery/addresses'
        }]
      },{
        name: 'Настройки',
        routes: [{
          name: 'Страны',
          path: '/settings/contries'
        }]
      }]
    }
  },
  methods: {
    /**
     * Выбор нужного роута
     * @param {{
     *  name: string,
     *  path: string
     * }} route
     */
    choose(route) {
      this.$router.push(route.path);
    }
  }
}
</script>

<style>

</style>