<template lang='pug'>
  .flex.h-full.w-full 
    template(v-if='auth')
      .left-bar.shadow-2xl.p-4(class='w-1/5')
        navigation
      .right-bar.p-4(class='w-4/5')
        slot
    template(v-else)
      auth
</template>

<script>
import { mapGetters } from 'vuex';

import Auth from './../components/Auth.vue';
import Navigation from './../components/Navigation.vue';

export default {
  components: {
    Auth,
    Navigation
  },
  computed: {
    ...mapGetters({
      auth: 'auth'
    })
  }
}
</script>

<style lang='scss'>
  .left-bar {
    height: 100%;
  }

  .right-bar {
    max-height: 100%;
    overflow: scroll
  }
</style>