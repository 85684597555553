<template lang='pug'>
#app.w-full.h-full
  modal
  
  main-layout.w-full.h-full
    router-view
</template>

<script>
import MainLayout from './layout/Main.vue';
import Modal from './components/Modal.vue';

export default {
  components: {
    MainLayout,
    Modal
  },
  mounted() {
    this.$store.dispatch('handshake')
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  height: 100vh;
}

input {
  background: whitesmoke;
  padding: 5px 10px !important;
  border-radius: 5px;
}

* {
  outline:none;
}

button {
  border-radius: 5px;

  width: fit-content;
  background: whitesmoke;
  padding: 5px 15px !important;

  transition: all 0.2s;

  &:active {
    transform: scale(0.95);
  }
}
</style>
