<template lang='pug'>
.flex.justify-center.items-center.w-full
  template(v-if='auth === null')
    .flex.flex-col
      .text-2xl.font-bold Авторизация в админ-панеле
      .text-sm Пожалуйста, подождите...
  template(v-else-if='auth === false')
    .flex.flex-col
      .text-2xl.font-bold Авторизация в админ-панеле
      .text-sm Доступ только для администраторов

      div.mt-4.flex.flex-col.gap-2
        input(placeholder='Почта / логин' v-model='form.email')
        input(placeholder='Пароль' type='password' v-model='form.password')

      button.mt-2.bg-green-500.text-white(@click='signIn()') Авторизация
  template(v-else)
    .text-4xl.font-bold ВЫ АВТОРИЗОВАНЫ
</template>

<script>
import { mapGetters } from 'vuex';
import { signIn } from './../assets/api.js';

export default {
  computed: {
    ...mapGetters({
      auth: 'auth'
    })
  },
  data() {
    return {
      form: {
        email: 'root@admin.com',
        password: 'root'
      }
    }
  },
  methods: {
    async signIn() {
      const result = await signIn(this.form);
      if (!result.success) {
        console.error('Введен неверный логин или пароль');
        return null;
      }

      this.$store.commit('token', result.data);
      this.$store.dispatch('handshake');      
    }
  }
}
</script>

<style>

</style>