import store from '../store/index.js';

const { default: axios } = require('axios');
const { rest: { host }} = require('./../../config.js');

const getToken = () => {
  try {
    const token = JSON.parse(window.localStorage.getItem('access_token'));
    if (typeof token != 'string') {
      return null;
    }

    return token;
  }
  catch {
    return null;
  }
}


axios.interceptors.request.use((config) => {
  config.baseURL = host;  
  config.headers['Authorization'] = getToken();

  return config;
});

axios.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  return error.response.data;
})

const handshake = async () => {
  return await axios.post('/api/account/handshake');
}

const signIn = async ({ email, password }) => {
  return await axios.post('/api/account/sign-in', {
    email,
    password
  });
}

const getCountries = async () => {
  return await axios.get('/api/countries');
}

const editCountry = async (country) => {
  return await axios.post('/api/countries', country);
}

const getWarehouses = async () => {
  return await axios.get('/api/warehouses');
}

const getWarehouse = async (id) => {
  return await axios.get('/api/warehouse', {
    params: {
      warehouseId: id
    }
  });
}

const getAddresses = async () => {
  return await axios.get('/api/addresses');
}

const getShelves = async (id) => {
  return await axios.get('/api/shelfes', {
    params: {
      warehouseId: id
    }
  });
}

const getParcels = async (id) => {
  return await axios.get('/api/parcels', {
    params: {
      id
    }
  });
}

const editParcel = async (data) => {
  return await axios.put(`/api/parcels/${data._id}`, data);
}

export {
  axios,
  handshake,
  signIn,
  getCountries,
  editCountry,
  getWarehouses,
  getWarehouse,
  getShelves,
  getAddresses,
  getParcels,
  editParcel
}