import Vue from 'vue'
import Vuex from 'vuex'
import { handshake } from './../assets/api.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialogQueue: [],
    auth: null
  },
  getters: {
    auth: ({ auth }) => auth,
    dialogQueue: ({ dialogQueue }) => dialogQueue
  },
  mutations: {
    auth: (state, payload) => {
      state.auth = payload;
    },
    token: ({ state }, token) => {
      window.localStorage.setItem('access_token', JSON.stringify(token));
    },
    dialogQueue: (state, { dialog, force }) => {
      if (force) {
        state.dialogQueue.unshift(dialog);
        return;
      }

      state.dialogQueue.push(dialog);
    },

    dialogClose: (state, { clear }) => {
      if (clear) {
        state.dialogQueue = [];
        return;
      }

      state.dialogQueue.splice(0, 1);
    }
  },
  actions: {
    handshake: async (context) => {
      const result = await handshake();
      if (!result.success) {
        console.error('Не удалось авторизоваться в сервисе');
        context.commit('auth', false);
        return;
      }

      context.commit('auth', {
        scope: result.data
      });
    }
  },
  modules: {
  }
})
